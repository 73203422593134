import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from '../../components/sectiontitle.jsx';
import Section from '../../components/section.jsx';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import NS1 from '../../images/portfolio/neoscroll/neoscroll-1.png';
import NS2 from '../../images/portfolio/neoscroll/neoscroll-2.png';
import NS3 from '../../images/portfolio/neoscroll/neoscroll-3.png';
import NS4 from '../../images/portfolio/neoscroll/neoscroll-4.png';


function PortfolioNeoscroll() {
  return (
    <Layout currentPage={[{title:"Portfolio", url:"portfolio"}, {title:"Neoscroll", url:"/portfolio/neoscroll"}]}>
      <SEO
        keywords={[`Robert Merki`, `Robmerki`, `Rob Merki`, `@robmerki`,`Merki.ca`]}
        title="Neoscroll"
      />

      
      <Section>
        <SectionTitle title="Neoscroll" />
        <p>
          Neoscroll is an intentionally simple question / answer app built to solve the "content marketing" problem on Google.
          Most topics found online are now answered with powerful SEO-engineering rather than good quality content.
        </p>
        <p>
          You can read more about the philosophy behind Neoscroll on my blogpost <Link to="/blog/finding-answers-sucks">here</Link>.
        </p>

        <p>
          You can see the live site here: <a href="https://neoscroll.com">Neoscroll.com</a>
        </p>

        <SectionTitle title="Tech" />
        <p>
          The backend uses Laravel, while the front-end was built with AlpineJS and TailwindCSS.
        </p>
        
        <p>
          It is hosted on a DigitalOcean Droplet and deployed using Github / Laravel Forge.
        </p>
        <SectionTitle title="Images" />

        <Zoom><img className="w-full h-auto" src={NS1} /></Zoom>
        <Zoom><img className="w-full h-auto" src={NS2} /></Zoom>
        <Zoom><img className="w-full h-auto" src={NS3} /></Zoom>
        <Zoom><img className="w-full h-auto" src={NS4} /></Zoom>

      </Section>
      
    </Layout>
  );
}


export default PortfolioNeoscroll;